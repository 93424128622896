import React, { useCallback, useEffect, useReducer, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import LoginForm from "./components/LoginForm";
import ScopeSelector from "./components/ScopeSelector";
import ReviewControls from "./components/ReviewControls";
import TimecardDisplay from "./components/TimecardDisplay";
import { Scope } from "./models/Scope";
import { Timecard } from "./models/Timecard";
import { TimecardReview } from "./models/TimecardReview";
import {
  submitReview,
  fetchTimecards,
  setAuthorizationHeader,
  fetchTeamProfile,
} from "./services/ApiService";
import { JWT_TOKEN_KEY } from "./config/authTokenKey";
import { showErrorToast } from "./services/ToastService";
import { preloadImages } from "./services/PreloadService";
import { clearScopeInLocalStorage, saveScopeToLocalStorage } from "./services/ScopePersistenceService";
import { TimecardAiReviewPair } from "./models/TimecardAiReviewPair";
import { Profile } from "./models/Profile";

const LOADING_TIMECARDS: Timecard[] = [];
const LOADING_AI_REVIEWS: TimecardReview[] = [];
const MAX_TIMECARDS_TO_PRELOAD = 3;

interface AppState {
  review: TimecardReview | null;
  timecards: Timecard[];
  aiReviews: TimecardReview[];
  username: string | null;
  isAuthenticated: boolean;
}

interface LoginAction {
  type: "login";
  newUsername: string;
}

interface LogoutAction {
  type: "logout";
}

interface ResetTimecardsAction {
  type: "reset-timecards";
}

interface SetReviewAction {
  type: "set-review";
  newReview:
  | TimecardReview
  | ((r: TimecardReview | null) => TimecardReview | null)
  | null;
}

interface SetTimecardsAction {
  type: "set-timecards";
  newTimecards: Timecard[] | ((tcs: Timecard[]) => Timecard[]);
  newAiReviews: TimecardReview[] | ((trs: TimecardReview[]) => TimecardReview[]);
}

interface NextTimecardAction {
  type: "next-timecard";
}

type ReducerAction =
  | LoginAction
  | LogoutAction
  | ResetTimecardsAction
  | SetReviewAction
  | ResetTimecardsAction
  | SetTimecardsAction
  | NextTimecardAction;

const reducer = (state: AppState, action: ReducerAction): AppState => {
  switch (action.type) {
    case "login":
      return { ...state, username: action.newUsername, isAuthenticated: true };
    case "logout":
      return { ...state, username: null, isAuthenticated: false };
    case "reset-timecards":
      return { ...state, review: null, timecards: LOADING_TIMECARDS, aiReviews: LOADING_AI_REVIEWS };
    case "set-review": {
      if (typeof action.newReview === "function")
        return { ...state, review: action.newReview(state.review) };
      return { ...state, review: action.newReview };
    }
    case "set-timecards": {
      const newState: AppState = {
        ...state,
        timecards:
          typeof action.newTimecards === "function"
            ? action.newTimecards(state.timecards)
            : action.newTimecards,
        aiReviews:
          typeof action.newAiReviews === "function"
            ? action.newAiReviews(state.aiReviews)
            : action.newAiReviews
      };
      if (newState.timecards.length === 0) {
        newState.review = null;
      } else if (
        state.review?.timecard?.timecard_id !==
        newState.timecards[0].timecard_id
      ) {
        newState.review = {
          timecard: newState.timecards[0],
          usesAi: newState.aiReviews[0]?.usesAi ?? undefined,
          aiTools: newState.aiReviews[0]?.aiTools ?? undefined,
          category: newState.aiReviews[0]?.category ?? undefined
        };
      }
      return newState;
    }
    case "next-timecard": {
      const newState: AppState = {
        ...state,
        timecards: state.timecards.slice(1),
        aiReviews: state.aiReviews.slice(1)
      };
      newState.review =
        newState.timecards.length === 0
          ? null
          : {
            timecard: newState.timecards[0],
            usesAi: newState.aiReviews[0]?.usesAi ?? undefined,
            aiTools: newState.aiReviews[0]?.aiTools ?? undefined,
            category: newState.aiReviews[0]?.category ?? undefined
          };
      return newState;
    }
    default:
      return state;
  }
};

export default function App() {
  const [scope, setScope] = useState<Scope>({
    team: null,
    user: null,
    week: null,
  });
  const [profile, setProfile] = useState<Profile | null>(null);
  const [state, dispatch] = useReducer(reducer, {
    isAuthenticated: false,
    username: null,
    review: null,
    timecards: LOADING_TIMECARDS,
    aiReviews: LOADING_AI_REVIEWS
  });
  const { review, timecards, username, isAuthenticated, aiReviews } = state;
  const currentAiReview = aiReviews.length > 0 ? aiReviews[0] : null

  const setReview = useCallback(
    (
      newReview:
        | TimecardReview
        | ((r: TimecardReview | null) => TimecardReview | null)
        | null
    ) => {
      dispatch({ type: "set-review", newReview });
    },
    []
  );

  const handleLoginSuccess = useCallback((token: string) => {
    localStorage.setItem(JWT_TOKEN_KEY, token);
    setAuthorizationHeader(token);
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    dispatch({ type: "login", newUsername: decodedToken.sub });
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(JWT_TOKEN_KEY);
    setAuthorizationHeader(null);
    clearScopeInLocalStorage();
    setScope({ team: null, user: null, week: null });
    dispatch({ type: "logout" });
  }, []);

  const scopeUser = scope.user;
  const onNext = useCallback(() => {
    if (review == null || scopeUser == null) return;
    if (review.usesAi == null) {
      showErrorToast("Please select AI usage.");
      return;
    }
    if (review.category == null) {
      showErrorToast("Please select a category.");
      return;
    }
    submitReview(scopeUser, review);
    dispatch({ type: "next-timecard" });
  }, [scopeUser, review]);

  const changeScope = useCallback((scopeChange: Partial<Scope>) => {
    setScope((scope: Scope): Scope => {
      // console.debug(`Scope change: ${JSON.stringify(scopeChange)}}`)
      // if team has changed or been reset, reset user field
      if (
        scopeChange.team !== undefined &&
        (scopeChange.team === null || scopeChange.team !== scope.team)
      ) {
        scopeChange.user = null;
      }
      // determine the new scope
      const newScope = { ...scope, ...scopeChange };
      console.debug(`New scope: ${JSON.stringify(newScope)}`);
      // if the user has changed, reload the timecards and start a new review
      if (newScope.user !== scope.user || newScope.week !== scope.week) {
        console.debug(`Loading timecards...`);
        dispatch({ type: "reset-timecards" });
        if (newScope.user && newScope.week) {
          fetchTimecards(newScope.user.assignment_id, newScope.week).then(
            (data: TimecardAiReviewPair[]) => {
              const timecards: Timecard[] = data.map(element => element.timecard);
              const aiReviews: TimecardReview[] = data.map(element => element.ai_review);
              console.debug(`Loaded ${timecards.length} timecards`);
              dispatch({ type: "set-timecards", newTimecards: timecards, newAiReviews: aiReviews });
            }
          );
        }
      }
      return newScope;
    });
  }, []);

  // This side effect ensures that we log the user in automatically
  // if there is a token persisted in local storage
  useEffect(() => {
    const token = localStorage.getItem(JWT_TOKEN_KEY);
    if (!!token) handleLoginSuccess(token);
  }, [handleLoginSuccess]);

  // This side effect ensures that when timecards change, we
  // always preload a fixed prefix of the timecard images.
  useEffect(() => {
    preloadImages(
      timecards.slice(0, MAX_TIMECARDS_TO_PRELOAD).map((t) => t.screenshot_url)
    );
  }, [timecards]);

  // Fetch team profile when the team changes
  useEffect(() => {
    if (scope.team) {
      fetchTeamProfile(scope.team.id).then(setProfile);
    }
  }, [scope.team]);

  // Save the scope to local storage whenever it changes
  useEffect(() => {
    if (scope == null || scope.week == null || scope.user == null || scope.team == null) {
      return
    }
    saveScopeToLocalStorage(scope);
  }, [scope]);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col justify-center items-center h-screen w-full">
        <ToastContainer />
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen w-full">
      <ToastContainer />
      <div className="flex items-center bg-gray-800 w-full">
        <h1 className="text-2xl font-bold my-2 ml-6 mr-10 text-white">
          Timecard&nbsp;Review
        </h1>
        <ScopeSelector scope={scope} changeScope={changeScope} />
        {username && (
          <p className="text-white text-lg font-semibold mr-4 self-center ml-auto">
            Logged in as: {username}
          </p>
        )}
        <button
          onClick={handleLogout}
          className="ml-auto mr-6 my-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Logout
        </button>
      </div>
      {!review && (
        <>
          <div
            className="flex justify-center items-center w-full h-full"
            style={{ background: "#DDDDDD" }}
          >
            {timecards === LOADING_TIMECARDS && (
              <>
                {/* <img alt="Loading..." src="https://placehold.co/192x120?text=Loading..." className="h-full w-full max-h-96" /> */}
                <span className="loader"></span>
              </>
            )}
            {timecards !== LOADING_TIMECARDS && timecards.length === 0 && (
              <>
                <img
                  alt="No Timecards"
                  src="https://placehold.co/192x120?text=No+Timecards"
                  className="h-full w-full max-h-96"
                />
              </>
            )}
          </div>
        </>
      )}
      {review && (
        <div className="flex-grow flex flex-row p-6 gap-6">
          <div className="" style={{ width: "calc(100vw - 430px - 3rem)" }}>
            <div className="flex justify-center items-center h-full">
              {review && <TimecardDisplay timecard={review?.timecard} />}
            </div>
          </div>
          <div className="" style={{ width: "430px" }}>
            {profile && (
              <ReviewControls
                review={review}
                setReview={setReview}
                onNext={onNext}
                aiReview={currentAiReview}
                profile={profile}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
