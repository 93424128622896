
export interface Team {
  id: number
  name: string
  company: string
}

export function teamsEqual(lhs: Team, rhs: Team): boolean {
  return lhs.id === rhs.id &&
    lhs.name === rhs.name &&
    lhs.company === rhs.company
}
