import React, { useState, useEffect, PropsWithChildren } from "react";
import { Team, teamsEqual } from "../models/Team";
import { User, usersEqual } from "../models/User";
import { Scope } from "../models/Scope";

import {
  fetchTeams,
  fetchUsers,
  fetchWeeks,
} from "../services/ApiService";
import { loadScopeFromLocalStorage } from "../services/ScopePersistenceService";

interface ScopeSelectorProps {
  scope: Scope;
  changeScope: (s: Partial<Scope>) => void;
}

export default function ScopeSelector({
  scope,
  changeScope,
}: ScopeSelectorProps) {
  const [weeks, setWeeks] = useState<string[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const scopeTeamId = scope?.team?.id;

  useEffect(() => {
    let unmounted = false;
    fetchWeeks()
      .then((weeks: string[]) => {
        if (unmounted) return;
        setWeeks(weeks);
        if (weeks.length === 0) return;
        const possibleScopeWeek = loadScopeFromLocalStorage()?.week;
        const defaultValue = possibleScopeWeek != null && weeks.includes(possibleScopeWeek) ? possibleScopeWeek : weeks[weeks.length - 1];
        changeScope({ week: defaultValue })
      })
    fetchTeams()
      .then((teams: Team[]) => {
        if (unmounted) return;
        setTeams(teams);
        if (teams.length === 0) return;
        const possibleTeam = loadScopeFromLocalStorage()?.team;
        const defaultValue = possibleTeam != null && teams.find(t => teamsEqual(t, possibleTeam)) != null ? possibleTeam : teams[0]
        changeScope({ team: defaultValue });
      })
    return () => {
      unmounted = true;
    };
  }, [changeScope]);

  // Any team change should trigger loading of users
  useEffect(() => {
    let unmounted = false;
    setUsers([]);

    if (scopeTeamId != null) {
      fetchUsers(scopeTeamId)
        .then((users: User[]) => {
          if (unmounted) return;
          setUsers(users);
          if (users.length === 0) return;
          const possibleUser = loadScopeFromLocalStorage()?.user
          const defaultValue = possibleUser != null && users.find(u => usersEqual(u, possibleUser)) != null ? possibleUser : users[0]
          changeScope({ user: defaultValue });
        })
    }

    return () => {
      unmounted = true;
    };
  }, [scopeTeamId, changeScope]);

  function handleTeamChange(event: any) {
    if (event.target.value === "" || event.target.value == null) {
      changeScope({ team: null });
      return;
    }

    const newTeamId = parseInt(event.target.value, 10);
    if (scope.team?.id === newTeamId) return;
    const newTeam = teams.find((team) => team.id === newTeamId);
    if (!newTeam) {
      console.error(`Cannot find team ${newTeamId}`);
      return;
    }
    changeScope({ team: newTeam });
  }

  function handleUserChange(event: any) {
    const newUserAssignmentId = parseInt(event.target.value, 10);
    if (scope.user?.assignment_id === newUserAssignmentId) return;
    const newUser = users.find((user) => user.assignment_id === newUserAssignmentId);
    if (!newUser) {
      console.error(`Cannot find user ${newUserAssignmentId}`);
      return;
    }
    changeScope({ user: newUser });
  }

  function handleWeekChange(event: any) {
    const newWeek = event.target.value;
    changeScope({ week: newWeek });
  }

  return (
    <div className="flex flex-row items-center px-6 gap-12 flex-grow">
      <Select
        selectId="team-select"
        label="Team"
        value={scope.team?.id ?? ""}
        onChange={handleTeamChange}
      >
        {teams.map((team) => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </Select>
      <Select
        selectId="user-select"
        label="User"
        value={scope.user?.assignment_id ?? ""}
        onChange={handleUserChange}
      >
        {users.map((user) => (
          <option key={user.assignment_id} value={user.assignment_id}>
            {user.name}
          </option>
        ))}
      </Select>
      <Select
        selectId="week-select"
        label="Week"
        value={scope.week ?? ""}
        onChange={handleWeekChange}
      >
        {weeks.map((week) => (
          <option key={week} value={week}>
            {week}
          </option>
        ))}
      </Select>
    </div>
  );
}

interface SelectProps {
  selectId: string;
  label: string;
  value: string | number;
  onChange: (event: any) => void;
}

const Select = ({
  selectId,
  label,
  value,
  onChange,
  children,
}: PropsWithChildren<SelectProps>) => {
  return (
    <div className="flex-1 flex flex-row items-center">
      <label
        htmlFor={selectId}
        className="inline-block text-white font-bold mr-4"
      >
        {label}
      </label>
      <select
        id={selectId}
        value={value}
        onChange={onChange}
        className={
          "my-2 inline-block border w-full pl-2 pr-4 py-2 text-base border-gray-300 focus:outline-none " +
          "focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        }
      >
        {children}
      </select>
    </div>
  );
};
