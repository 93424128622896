import { useCallback } from "react";
import { TimecardReview } from "../models/TimecardReview";
import { Category } from "../models/Category";
import HotKeyIcon from "./HotKeyIcon";
import useHotKeyListener from "../hooks/useHotKeyListener";
import { CATEGORY_HOTKEYS } from "../config/hotkeys";
import { getColor } from "../models/Colors";

interface CategorySelectorProps {
  review: TimecardReview;
  setReview: ((r: TimecardReview | null) => void) &
  ((fn: (r: TimecardReview | null) => TimecardReview | null) => void);
  aiReview: TimecardReview | null;
  categories: Category[];
}

const CategorySelector = ({
  review,
  setReview,
  aiReview,
  categories,
}: CategorySelectorProps) => {

  return (
    <div className="flex flex-col flex-basis-50 flex-grow mr-4 ml-4">
      <label className="mb-2 text-black font-bold">1. Category</label>
      <div className="grid grid-cols-1 gap-2">
        {categories.map((category, index) => (
          <CategoryButton
            key={index}
            index={index}
            category={category}
            hotKey={CATEGORY_HOTKEYS[index]}
            review={review}
            setReview={setReview}
            aiRecommendation={aiReview?.category === category.name}
          />
        ))}
      </div>
    </div>
  );
};

interface CategoryButtonProps {
  category: Category;
  index: number,
  hotKey: string;
  review: TimecardReview;
  setReview: ((r: TimecardReview | null) => void) &
  ((fn: (r: TimecardReview | null) => TimecardReview | null) => void);
  aiRecommendation: boolean;
}

const CategoryButton = ({
  category,
  index,
  hotKey,
  review,
  setReview,
  aiRecommendation,
}: CategoryButtonProps) => {
  const isSelected = category.name === review.category

  const onClick = useCallback(() => {
    setReview((r) => (r ? { ...r, category: category.name } : null));
  }, [setReview, category]);

  useHotKeyListener(hotKey, onClick);

  return (
    <button
      className={`border-2 border-black font-bold py-1 px-4 rounded`}
      style={{
        backgroundColor: isSelected
          ? "#131313"
          : getColor(index),
        color: isSelected ? "#ffffff" : "#131313",
      }}
      onClick={onClick}
    >
      <span className="flex flex-row justify-between">
        <span>{category.name}</span>
        {aiRecommendation && <span className="italic">AI Suggestion</span>}
        <span className="inline-block">
          {isSelected ? <CheckmarkIcon /> : <HotKeyIcon unicode={hotKey} />}
        </span>
      </span>
    </button>
  );
};

const CheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="white"
      viewBox="0 0 28 28"
    >
      <path d="M20.285 5.708a1 1 0 00-1.41 0l-10.36 10.36-3.86-3.86a1 1 0 10-1.41 1.41l4.57 4.57a1 1 0 001.41 0l11.07-11.07a1 1 0 000-1.41z" />
    </svg>
  );
};

export default CategorySelector;
