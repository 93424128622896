import React from 'react';

type HotKeyIconProps = {
  unicode: string;
  widthPx?: number;
  smallText?: boolean
};

const HotKeyIcon = ({ unicode, widthPx, smallText }: HotKeyIconProps) => {
  return (
    <span className={`bg-white shadow-md text-black rounded text-center inline-flex items-center justify-center px-1 ${smallText ? 'text-xs' : ''}`} style={{ width: widthPx ?? 24 }}>
      {unicode}
    </span>
  );
};

export default HotKeyIcon;
