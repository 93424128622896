import { useEffect } from 'react';
import { SUBMIT_HOTKEY, UNFOCUS_TEXTBOX_HOTKEY } from '../config/hotkeys';

const useHotKeyListener = (hotKey: string | undefined, onPress: () => void) => {
  useEffect(() => {
    if (hotKey == null) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.target &&
        (event.target instanceof HTMLTextAreaElement ||
          (event.target instanceof HTMLInputElement && (!event.target.type || event.target.type === "text")))
      ) {
        if (event.code !== SUBMIT_HOTKEY && event.code !== UNFOCUS_TEXTBOX_HOTKEY) return;
      }

      if (event.code === hotKey || event.key.toLowerCase() === hotKey.toLowerCase()) {
        event.preventDefault();
        onPress();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [hotKey, onPress]);
};

export default useHotKeyListener;
