import { TimecardReview } from "../models/TimecardReview";
import AIUsageSelector from "./AIUsageSelector";
import CategorySelector from "./CategorySelector";
import HotKeyIcon from "./HotKeyIcon";
import NextArrow from "./NextArrow";
import useHotKeyListener from "../hooks/useHotKeyListener";
import { SUBMIT_HOTKEY } from "../config/hotkeys";
import { Profile } from "../models/Profile";

interface ReviewControlsProps {
  review: TimecardReview;
  setReview: ((r: TimecardReview | null) => void) &
  ((fn: (r: TimecardReview | null) => TimecardReview | null) => void);
  onNext: () => void;
  aiReview: TimecardReview | null;
  profile: Profile;
}

export default function ReviewControls({
  review,
  setReview,
  onNext,
  aiReview,
  profile,
}: ReviewControlsProps) {
  useHotKeyListener(SUBMIT_HOTKEY, onNext);

  return (
    <div className="flex flex-col gap-5">
      <CategorySelector
        review={review}
        setReview={setReview}
        aiReview={aiReview}
        categories={profile.categories}
      />
      <AIUsageSelector
        review={review}
        setReview={setReview}
        aiReview={aiReview}
        tools={profile.tools}
      />
      <div className="mt-2">
        <button
          className="border-2 bg-black text-white border-black hover:bg-gray-600 font-bold py-2 px-4 rounded-lg w-full"
          onClick={onNext}
        >
          <span className="m-2">Next</span>
          <HotKeyIcon unicode="&#x23CE;" />
          <span className="float-right">
            <NextArrow />
          </span>
        </button>
      </div>
      {aiReview && (!!aiReview.explanation) && (
        <div className="text-sm">
          <h3 className="text-gray-600 font-semibold">🧠 AI Explanation</h3>
          <p className="">{aiReview.explanation}</p>
        </div>
      )}
    </div>
  );
}
