import React from "react";
import { Timecard } from "../models/Timecard";
import { TimecardEvent } from "../models/TimecardEvent";

interface TimecardProps {
  timecard: Timecard;
}

const TimecardDisplay: React.FC<TimecardProps> = ({ timecard }) => {
  const placeholderImage = "https://placehold.co/192x120";
  const timecardImage = timecard.screenshot_url || placeholderImage;

  return (
    <div>
      <div className="text-lg font-bold">
        {new Date(timecard.date).toLocaleString()} -
        {timecard.window_title && timecard.window_title.length > 80
          ? `${timecard.window_title?.substring(0, 80)}...`
          : timecard.window_title}
      </div>
      <div>
        {/* The 'key' here ensures that the image is reconstructed when the url changes. */}
        <img alt="Timecard" src={timecardImage} key={timecardImage} />
      </div>
      <div className="p-4">
        <table className="table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Process</th>
              <th className="px-4 py-2">Window</th>
            </tr>
          </thead>
          <tbody>
            {timecard.events.map((event: TimecardEvent, index: number) => (
              <tr key={index}>
                <td className="border px-4 py-2">{event.process_name}</td>
                <td className="border px-4 py-2">
                  {event.window_title !== event.visiting_url && (
                    <>
                      <span className='text-sm' style={{
                        fontWeight: 600,
                        textOverflow: "ellipsis",
                        textWrap: "wrap",
                        wordBreak: "break-word",
                      }}>
                        {event.window_title && event.window_title.length > 100
                          ? `${event.window_title.substring(0, 100)}...`
                          : event.window_title}
                      </span>
                      <br />
                    </>
                  )}
                  <a className='text-sm'
                    href={event.visiting_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textOverflow: "ellipsis",
                      textWrap: "wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {event.visiting_url && event.visiting_url.length > 100
                      ? `${event.visiting_url.substring(0, 100)}...`
                      : event.visiting_url}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimecardDisplay;
