import { useCallback, useRef } from "react";
import { TimecardReview } from "../models/TimecardReview";
import useHotKeyListener from "../hooks/useHotKeyListener";
import HotKeyIcon from "./HotKeyIcon";
import {
  AI_TOOLS_HOTKEYS,
  YES_HOTKEY,
  NO_HOTKEY,
  UNFOCUS_TEXTBOX_HOTKEY,
} from "../config/hotkeys";
import { Tool } from "../models/Tool";

const SELECTED_CLASSES = "bg-blue-500 text-white border-black";
const DEFAULT_CLASSES = "bg-white text-black border-black hover:bg-gray-200";

interface AIUsageSelectorProps {
  review: TimecardReview;
  setReview: ((r: TimecardReview | null) => void) &
  ((fn: (r: TimecardReview | null) => TimecardReview | null) => void);
  aiReview: TimecardReview | null;
  tools: Tool[];
}

const AIUsageSelector = ({
  review,
  setReview,
  aiReview,
  tools,
}: AIUsageSelectorProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onPressYes = useCallback(
    () => setReview((r) => (r ? { ...r, usesAi: true } : null)),
    [setReview]
  );
  const onPressNo = useCallback(
    () => setReview((r) => (r ? { ...r, usesAi: false } : null)),
    [setReview]
  );

  useHotKeyListener(YES_HOTKEY, onPressYes);
  useHotKeyListener(NO_HOTKEY, onPressNo);

  // This side effect ensures that we focus on the textbox whenever "Yes" is selected.
  // const reviewUsesAi = review?.usesAi ?? false
  // useEffect(() => {
  //   if (!reviewUsesAi) return;
  //   setTimeout(() => { inputRef.current?.focus(); }, 0);
  // }, [reviewUsesAi, inputRef]);

  // This side effect allows us to unfocus the textbox if we press Escape
  const onPressEsc = useCallback(() => {
    setTimeout(() => {
      inputRef.current?.blur();
    }, 0);
  }, [inputRef]);
  useHotKeyListener(UNFOCUS_TEXTBOX_HOTKEY, onPressEsc);

  return (
    <div className="flex flex-col flex-basis-50 items-left justify-left gap-8 ml-4 mr-4">
      <div className="flex flex-col gap-4">
        <label className="text-black font-bold">2. Using AI?</label>
        <div className="flex flex-row gap-2">
          <button
            className={`border-2 ${review.usesAi === true ? SELECTED_CLASSES : DEFAULT_CLASSES
              } hover:button-hover font-bold py-2 px-4 rounded flex-1`}
            onClick={() => setReview({ ...review, usesAi: true })}
          >
            {aiReview && aiReview.usesAi === true && <BrainIcon />}
            <span className="mr-2">Yes</span>
            <HotKeyIcon unicode={YES_HOTKEY} />
          </button>
          <button
            className={`border-2 ${review.usesAi === false ? SELECTED_CLASSES : DEFAULT_CLASSES
              } hover:button-hover font-bold py-2 px-4 rounded flex-1`}
            onClick={() => setReview({ ...review, usesAi: false, aiTools: "" })}
          >
            {aiReview && aiReview.usesAi === false && <BrainIcon />}
            <span className="mr-2">No</span>
            <HotKeyIcon unicode={NO_HOTKEY} />
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className={`flex flex-row ${review.usesAi ? "" : "invisible"}`}>
          <label className="text-black font-bold mr-2">3. What AI Tools?</label>
          <span className="text-xs inline-flex items-center justify-center">
            {"("}
            <HotKeyIcon unicode="Esc" widthPx={30} smallText />
            &nbsp;{"to unfocus)"}
          </span>
        </div>
        <div className="flex flex-col">
          <input
            ref={inputRef}
            type="text"
            disabled={!review.usesAi}
            className={`border-2 border-black py-2 px-4 rounded ${review.usesAi ? "" : "invisible"}`}
            placeholder="Enter AI tools used"
            value={review.aiTools ?? ""}
            onChange={(e) => setReview({ ...review, aiTools: e.target.value })}
            autoComplete="off" // AI-GEN - Cursor and GPT4
            data-lpignore="true" // For LastPass
            data-form-type="other" // For Dashlane
            data-dashlane-rid={Math.random().toString()} // AI-GEN - Cursor and GPT4
          />
          <div
            className={`flex flex-wrap gap-2 mt-2 ${review.usesAi ? "" : "invisible"}`}
          >
            {tools.map((tool, index) => (
              <AIToolButton
                key={index}
                tool={tool}
                hotKey={AI_TOOLS_HOTKEYS[index]}
                isSelected={review.aiTools === tool.name}
                setReview={setReview}
                disabled={!review.usesAi}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const BrainIcon = () => (
  <span className="float-left bg-yellow-100 rounded-lg shadow pr-1 pl-1">
    🧠
  </span>
);

interface AIToolButtonProps {
  tool: Tool;
  hotKey?: string;
  isSelected: boolean;
  setReview: ((r: TimecardReview | null) => void) &
  ((fn: (r: TimecardReview | null) => TimecardReview | null) => void);
  disabled: boolean;
}

const AIToolButton = ({
  tool,
  hotKey,
  isSelected,
  setReview,
  disabled,
}: AIToolButtonProps) => {
  const onClick = useCallback(() => {
    setReview((r) => (r ? { ...r, aiTools: tool.name } : null));
  }, [setReview, tool]);

  useHotKeyListener(hotKey, onClick);

  return (
    <button
      className={`border border-gray-300 font-bold py-2 px-4 rounded-lg text-xs ${isSelected ? "bg-black text-white" : "hover:bg-gray-200"
        }`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="m-2">{tool.name}</span>
      {hotKey && <HotKeyIcon unicode={hotKey} />}
    </button>
  );
};

export default AIUsageSelector;
