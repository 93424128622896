
export interface User {
  assignment_id: number
  xo_user_id: number
  name: string
}

export function usersEqual(lhs: User, rhs: User): boolean {
  return lhs.assignment_id === rhs.assignment_id &&
    lhs.xo_user_id === rhs.xo_user_id &&
    lhs.name === rhs.name;
}
