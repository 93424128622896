import React, { useState } from "react";
import axios from "axios";
import { login } from "../services/ApiService";
import "@aws-amplify/ui-react/styles.css";
import { showErrorToast } from "../services/ToastService";

export default function LoginForm({
  onLoginSuccess,
}: {
  onLoginSuccess: (token: string) => void;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const token = await login(username, password);
      onLoginSuccess(token);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 401
      ) {
        showErrorToast("Invalid credentials, please try again.");
      } else {
        showErrorToast("An error occurred during login. Please try again later.");
      }
    }
  };

  return (
    <div className="flex flex-row w-full">
      <div className="w-1/6 p-4"></div>
      <div className="w-2/6 p-4">
        <h2 className="block text-gray-700 text-2xl font-bold mb-4">
          Welcome to Lens
        </h2>
        <ul className="list-disc list-inside text-gray-700 text-xl mb-4">
          <li>Review timecards with ease.</li>
          <li>AI assisted reviews</li>
          <li>Use keyboard shortcuts</li>
          <li>Login using your WS credentials</li>
        </ul>
      </div>
      <div className="border-r border-gray-300"></div>
      <div className="w-2/6 p-4">
        <form
          onSubmit={handleSubmit}
          className="bg-white rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-4">
            <h2 className="block text-gray-700 text-2xl font-bold mb-4 text-center">
              Login to your Crossover account
            </h2>
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="username"
            >
              Email address
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Email address"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
            />
          </div>
          <div className="flex items-center justify-between">
            <a
              href="https://app.crossover.com/x/reset/password"
              className="text-blue-500 hover:text-blue-700 font-bold py-2 px-4"
            >
              Forgot Password?
            </a>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              <i className="fas fa-sign-in-alt"></i> Login &gt;
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/6 p-4"></div>
    </div>
  );
}
