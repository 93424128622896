import axios from 'axios';
import { TimecardReview } from '../models/TimecardReview';
import { User } from '../models/User';
import { JWT_TOKEN_KEY } from '../config/authTokenKey';
import { showErrorToast } from './ToastService';
import { clearScopeInLocalStorage } from './ScopePersistenceService';
import { Profile } from '../models/Profile';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function getCategoryName(category: any) {
  return category == null ? null
    : typeof category === 'string' ? category
      : typeof category === 'object' && 'name' in category ? category.name
        : null;
}

export async function fetchTeams() {
  try {
    const response = await axios.get(`${BASE_URL}/api/teams`)
    return response.data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array to signify no data could be fetched.
  }
}

export async function fetchTeamProfile(teamId: number): Promise<Profile | null> {
  try {
    const response = await axios.get(`${BASE_URL}/api/teams/${teamId}/profile`);
    return response.data
  } catch (error) {
    handleApiError(error);
    return null; // Return null to signify no data could be fetched.
  }
}

export async function fetchWeeks() {
  try {
    const response = await axios.get(`${BASE_URL}/api/week-options`)
    return response.data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array to signify no data could be fetched.
  }
}

export async function fetchUsers(teamId: number) {
  try {
    const response = await axios.get(`${BASE_URL}/api/teams/${teamId}/people`)
    return response.data
  } catch (error) {
    handleApiError(error);
    return [];
  }
}

export async function login(username: string, password: string) {
  try {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    const response = await axios.post(`${BASE_URL}/api/token`, formData);
    return response.data.access_token;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
}

export async function fetchTimecards(assignmentId: number, startOfWeek: string) {
  try {
    const response = await axios.get(`${BASE_URL}/api/people/${assignmentId}/timecards`, {
      params: {
        start_of_week: startOfWeek
      }
    });
    return response.data.map((pair: { timecard: any, ai_review: any }) => ({
      timecard: pair.timecard,
      ai_review: pair.ai_review == null
        ? null
        : {
          timecard_id: pair.ai_review.timecard_id,
          usesAi: pair.ai_review.uses_ai,
          aiTools: pair.ai_review.ai_tools,
          category: getCategoryName(pair.ai_review.category),
          explanation: pair.ai_review.explanation
        }
    }))
  } catch (error) {
    handleApiError(error);
    return [];
  }
}

export async function submitReview(user: User, review: TimecardReview) {
  try {
    const response = await axios.post(`${BASE_URL}/api/timecards/${review.timecard.timecard_id}/review`, {
      timecard_id: review.timecard.timecard_id,
      assignment_id: user.assignment_id,
      xo_user_id: user.xo_user_id,
      timecard_date: review.timecard.date,
      category: review.category,
      uses_ai: review.usesAi,
      ai_tools: review.aiTools
    })
    return response.data;
  } catch (error) {
    handleApiError(error);
    return null;
  }
}

export function setAuthorizationHeader(token: string | null) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common['Authorization'] = ''
  }
}

function handleApiError(error: any) {
  if (axios.isAxiosError(error) && error.response) {
    const status = error.response.status;
    if (status === 401) {
      localStorage.removeItem(JWT_TOKEN_KEY);
      // In case the user logs in to a DIFFERENT account, we need to clear scope.
      clearScopeInLocalStorage();
      showErrorToast('Session expired or invalid. Refreshing now...');
      setTimeout(() => window.location.reload(), 2000);
    } else if (status === 404) {
      showErrorToast('Resource not found. Please try again later.');
    } else if (status === 403) {
      showErrorToast(`Unauthorized: ${error.message}`);
    } else {
      showErrorToast(`Error: ${status} - ${error.message}`);
    }
  } else {
    showErrorToast(`Error: ${error.message}`);
  }
}
