import { Scope } from "../models/Scope";

const SCOPE_KEY = 'scope'

export function loadScopeFromLocalStorage(): Scope | null {
  const encodedScope = localStorage.getItem(SCOPE_KEY)
  if (encodedScope == null) {
    return null
  }
  const possibleScope = JSON.parse(encodedScope)
  return {
    week: possibleScope.week ?? null,
    user: possibleScope.user ?? null,
    team: possibleScope.team ?? null
  }
}

export function saveScopeToLocalStorage(scope: Scope): void {
  if (scope == null) {
    clearScopeInLocalStorage()
    return
  }
  const encodedScope = JSON.stringify(scope)
  localStorage.setItem(SCOPE_KEY, encodedScope)
}

export function clearScopeInLocalStorage(): void {
  localStorage.removeItem(SCOPE_KEY)
}
