export async function preloadImage(imageUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = imageUrl;
  });
}

/**
 * Preloads a list of image URLs, tolerating failures
 * @param imageUrls - Array of image URLs to preload
 */
export async function preloadImages(imageUrls: string[]): Promise<void> {
  const promises = imageUrls.map((imageUrl) => preloadImage(imageUrl).catch((error) => { }));
  await Promise.all(promises);
}
